import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Empty, Form } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import { Loader } from "../../common";
import moment from "moment";

const TopUpHistoryList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const loc = useLocation();
  const route = useParams();
  const companyTopUpId = (route as any).topupId;
  const [form] = Form.useForm();

  const [showSearch, setShowSearch] = useState(true);
  const [topUpHistoryData, setTopUpHistoryData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTopUpHistory = useCallback(async () => {
    setTopUpHistoryData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(`${encodedUri}/topup/all?companyTopUpId=${companyTopUpId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTopUpHistoryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTopUpHistoryData({ loading: false, data: [] });
        console.error("Banners: Error", err);
      });
  }, [companyTopUpId]);

  //

  useEffect(() => {
    getTopUpHistory();
  }, [getTopUpHistory]);

  return (
    <>
      <BreadCrumb
        title="TopUp History"
        subTitle={`${
          topUpHistoryData?.data?.totalElements ||
          topUpHistoryData?.data?.topUps?.length ||
          0
        } Topup(s)`}
        extra={<></>}
      />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {topUpHistoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">SL</th>
                      <th scope="col">Date</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Operator</th>
                      <th scope="col">Type</th>
                      <th scope="col">TopUpId</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {topUpHistoryData?.data?.topUps?.length ? (
                      topUpHistoryData?.data?.topUps?.map(
                        (topup: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {index + 1}
                              </span>
                            </td>
                            <td
                              onClick={() =>
                                navigate(`/topuphistory/${topup?.id}/details`)
                              }
                            >
                              <span className="font-medium text-gray-500 ml-2">
                                {moment(topup?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.mobileNumber?.split("+88")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.operator}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.type}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.topUpId}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.amount}
                              </span>
                            </td>
                            <td
                              style={
                                topup?.status == "Success" &&
                                topup?.rechargeStatus == "900"
                                  ? { color: "green" }
                                  : { color: "red" }
                              }
                            >
                              {topup?.rechargeStatus == "900"
                                ? "Success"
                                : "Faile"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopUpHistoryList;
function UnLoadWindow() {
  throw new Error("Function not implemented.");
}
