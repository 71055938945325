import { SwapOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Button,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Select,
  Spin,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";
import EmployeeExtra from "./topup";

const EmployeeTopUp = (): ReactElement => {
  const fetchRef = useRef(0);
  const navigate = useNavigate();
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(20);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [employeeTopUpVisible, setEmployeeTopUpVisible] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<any>([]);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [activeClone, setActiveClone] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [employeeMap, setEmployeeMap] = useState<{ [key: string]: any }>({});
  const [mobileNumber, setMobileNumber] = useState("");

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [groupId, setGroupId] = useState<any>();
  const [groupOptions, setGroupOptions] = useState({
    loading: false,
    list: [],
  });
  const [employeeData, setEmployeeData] = useState<any>({
    loading: false,
    data: null,
  });
  const [employeeOptions, setEmployeeOptions] = useState({
    loading: false,
    list: [],
  });
  const onChangeCheckbox = (e: any) => {
    setSelectedEmployeeId((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length && checkedList.length <= selectedEmployeeId.length
    );
    setCheckAll(checkedList.length === selectedEmployeeId.length);
  };
  const getGroupOptions = useCallback(
    async (key?: string) => {
      setGroupOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      const res = await axios.get(
        `${encodedUri}/company/admin/all?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGroupOptions({
        loading: false,
        list: res?.data?.groups?.map((group: any) => ({
          label: group.groupName,
          value: group.id,
        })),
      });
    },
    [companyId]
  );
  const getEmployeeOptions = useCallback(
    async (mobileNumber: any) => {
      setEmployeeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(`${encodedUri}/employee/all?companyId=${companyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setEmployeeOptions({
            loading: false,
            list: res.data?.employees?.map(
              (employee: { name: any; mobileNumber: string }) => ({
                label: employee.name,
                value: employee.mobileNumber.replace("+88", ""),
              })
            ),
          });
        })
        .catch((err) => {
          setEmployeeOptions({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [companyId]
  );
  const getEmployee = useCallback(async () => {
    setEmployeeData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/employee/all?companyId=${companyId}` +
          (groupId ? `&groupId=${groupId}` : ``) +
          (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``) +
          (page
            ? `&page=${
                page == employeeData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setEmployeeData({ loading: false, data: res.data });
        setCheckedList(res.data.employees.map((employee: any) => employee?.id));

        const tempEmployeeMap = {};
        res?.data?.employees?.map((employee, i) => {
          // console.log(employee, 111);
          if (employee?.id)
            tempEmployeeMap[employee?.id] = {
              amount: employee?.monthlyTopUpAmount,
              designation: employee?.designationDetails?.name,
              mobileNumber: employee?.mobileNumber,
              name: employee?.name,
              operator: employee?.mobileOperator,
              type: employee?.simType,
            };
        });

        setEmployeeMap(tempEmployeeMap);
      })
      .catch((err) => {
        setEmployeeData({ loading: false, data: [] });
        console.error("Employees: Error", err);
      });
  }, [companyId, groupId, mobileNumber, page, limit]);

  const onCheckAllChange = (e: any) => {
    setSelectedEmployeeId(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const reseAllFieldData = () => {
    setGroupId("");
    setMobileNumber("");
    form.resetFields();
  };

  const showDrawer = () => {
    setEmployeeTopUpVisible(true);
  };

  useEffect(() => {
    getEmployee();
  }, [getEmployee]);

  const onClose = () => {
    getEmployee();
    setVisible(undefined);
    setSelectedEmployee(undefined);
    setEmployeeTopUpVisible(false);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "group") getGroupOptions(value);
        if (field === "employee") getEmployeeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getGroupOptions, getEmployeeOptions]);
  useEffect(() => {
    if (showSearch) {
      getGroupOptions("");
    }
  }, [showSearch]);

  return (
    <>
      <BreadCrumb
        title="TopUp Employee List"
        subTitle={`${
          employeeData?.data?.totalElements ||
          employeeData?.data?.employees?.length ||
          0
        } Employee(s)`}
        extra={[
          employeeData?.data?.employees?.length && (
            <Button
              shape="round"
              type="primary"
              onClick={() => showDrawer()}
              disabled={!selectedEmployeeId.length}
              title="Clone Product"
              className="bg-blue-600"
            >
              <SwapOutlined /> Action
            </Button>
          ),
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="phone_search" initialValue={mobileNumber}>
              <AutoComplete
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "employee")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setMobileNumber(val?.toString())
                }
                options={employeeOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  employeeOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by User Phone"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  loading={employeeOptions.loading}
                  maxLength={11}
                  type="number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="group_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Group"
                optionFilterProp="children"
                onChange={(val) => setGroupId(val)}
                onSearch={(e) => handleSearch(e, "group")}
                filterOption={() => {
                  return true;
                }}
                options={groupOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {employeeData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">
                        <div className="checkbox-large">
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={
                              checkAll ||
                              selectedEmployeeId?.length === checkedList?.length
                            }
                            className="mr-3"
                            disabled={!employeeData.data?.employees?.length}
                          >
                            Check all
                          </Checkbox>
                        </div>
                      </th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Operator</th>
                      <th scope="col">Type</th>
                      <th scope="col">TopUp Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {employeeData?.data?.employees?.length ? (
                      employeeData?.data?.employees?.map(
                        (employee: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedEmployee(employee)}
                          >
                            <td>
                              <div className="checkbox-large flex items-center gap-2">
                                <Checkbox
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onChangeCheckbox(employee?.id);
                                  }}
                                  checked={selectedEmployeeId?.find(
                                    (item: any) => employee?.id === item
                                  )}
                                  value={employee?.id}
                                ></Checkbox>
                                <span className="font-medium text-gray-500 ml-2">
                                  {employee?.name}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {employee?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <span>{employee?.designationDetails?.name}</span>
                            </td>
                            <td>{employee?.mobileOperator}</td>
                            <td>
                              <span>{employee?.simType}</span>
                            </td>
                            <td>
                              <span className="font-bold text-right">
                                &#2547;{employee?.monthlyTopUpAmount}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Drawer
          title={activeClone ? "Clone Product" : "Change Option"}
          placement="right"
          onClose={onClose}
          open={employeeTopUpVisible}
          width={550}
        >
          <EmployeeExtra
            employees={employeeMap}
            selectedEmployeeId={selectedEmployeeId}
          />
        </Drawer>

        <Pagination
          {...employeeData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default EmployeeTopUp;
