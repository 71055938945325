import { Button, Form, Input, Select } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router";
import { mobileOperatorType, simOperatorType } from "../../utils";
import { SubmitResetBtn } from "../common";
import { LOGOUT } from "../../redux/auth/authType";
import { logout } from "../../utils/auth";
import { dispatch } from "d3";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const Logout = () => {
    navigate("/");
    logout("/");
    dispatch({
      type: LOGOUT,
      payload: {
        token: null,
      },
    });
  };

  const onPasswordUpdate = async (data: any) => {
    console.log("Clicked");
    setLoading(true);
    const readyData = data && {
      mobileNumber: initialState?.mobileNumber,
      oldPassword: data?.oldPassword,
      newPassword: data?.newPassword,
    };

    await fetch(`${process.env.REACT_APP_AUTH_API}/company/update-password`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${initialState?.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          form.resetFields();
          Logout();
          responseNotification("Password Change Successfully", "success");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  return (
    <div className="mt-6">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onPasswordUpdate}
        form={form} // like ref
        layout="vertical"
      >
        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
          <div className="grid grid-cols-2 gap-x-6">
            <div>
              <Form.Item
                label="Old Password "
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your password! ",
                  },
                ]}
                // hasFeedback
              >
                <Input.Password
                  maxLength={20}
                  placeholder="Enter Old Password"
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                // hasFeedback
              >
                <Input.Password maxLength={20} placeholder="Enter Password" />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="newPassword"
                dependencies={["password"]}
                // hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password maxLength={20} placeholder="Confirm Password" />
              </Form.Item>
            </div>
          </div>
          <SubmitResetBtn />
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;
