import { Button, Form, Input, Select } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router";
import { mobileOperatorType, simOperatorType } from "../../utils";
import { SubmitResetBtn } from "../common";

const AddEmployee = ({ visibleData, onCloseMethod }: any) => {
  const fetchRef = useRef(0);
  const route = useParams();
  const navitage = useNavigate();
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [operatorType, setOperatorType] = useState();
  const [mobileOperator, setMobileOperator] = useState();
  const [employeeData, setEmployeeData] = useState<any>({
    loading: false,
    data: null,
  });
  const [groupId, setGroupId] = useState<any>();
  const [designationId, setDesignationId] = useState<any>();
  const [departmentId, setDepartmentId] = useState<any>();
  const [groupOptions, setGroupOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [designation, setDesignation] = useState<any>({
    list: [],
    loading: false,
  });
  const [departmentOptions, setDepartmentOptions] = useState({
    loading: false,
    list: [],
  });
  const fetchemployeeDetails = useCallback((getEmployeeId: any) => {
    if (getEmployeeId) {
      try {
        setEmployeeData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/employee/details?employeeId=${getEmployeeId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setEmployeeData({
                loading: false,
                data: data?.data?.employee,
              });
            } else {
              setEmployeeData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setEmployeeData({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setEmployeeData({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);
  useEffect(() => {
    fetchemployeeDetails((route as any)?.employeeId);
  }, [fetchemployeeDetails]);
  const getgroupOptions = useCallback(
    async (val?: string) => {
      setGroupOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/company/admin/all?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGroupOptions({
        loading: false,
        list: res?.data?.groups?.map((group) => {
          return {
            value: group?.id,
            label: group?.groupName,
          };
        }),
      });
    },
    [companyId]
  );
  const getDesignationOptions = useCallback(
    async (val?: string) => {
      setDesignation({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/company/department/designation/all?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDesignation({
        loading: false,
        list: res?.data?.designations?.map((designation) => {
          return {
            value: designation?.id,
            label: designation?.name,
          };
        }),
      });
    },
    [companyId]
  );

  const getDepartmentOptions = useCallback(
    async (key?: string) => {
      setDepartmentOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/company/department/all?companyId=${companyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDepartmentOptions({
            loading: false,
            list: res.data?.departments?.map((department: any) => ({
              label: department?.name,
              value: department?.id,
            })),
          });
        })
        .catch((err) => {
          setDepartmentOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [companyId]
  );
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      employees: [
        {
          creditLimit: parseFloat(data.creditLimit),
          discountPercentage: parseFloat(data.discountPercentage),
          emailAddress: data.emailAddress,
          mobileNumber: `+88${data.mobileNumber?.replace("+88", "")}`,
          mobileOperator: mobileOperator,
          simType: operatorType,
          monthlyTopUpAmount: data.monthlyTopUpAmount,
          name: data.name,
          employeeId: data.employeeId,
          department: departmentId,
          designation: designationId,
          isActive: true,
        },
      ],
      groupId: groupId,
      companyId: companyId,
    };

    if (employeeData?.data) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Employee Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
            navitage(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Employee Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
            navitage(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    getgroupOptions();
  }, [getgroupOptions]);

  useEffect(() => {
    getDesignationOptions();
  }, [getDesignationOptions]);

  useEffect(() => {
    if (visibleData) {
      setEmployeeData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    getDepartmentOptions();
  }, [getDepartmentOptions]);

  // useEffect(() => {
  //   if (employeeData) {
  //     form.resetFields(Object.keys(visibleData));
  //   }
  // }, [employeeData, form, visibleData]);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "group") getgroupOptions(value);
        else if (field === "designation") getDesignationOptions(value);
        else if (field === "department") getDepartmentOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getgroupOptions]);

  useEffect(() => {
    if (employeeData?.data) {
      form?.resetFields();
    }
  }, [employeeData]);

  return (
    <div className="mt-6">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...employeeData?.data,
        }}
      >
        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-4">
          <div className="grid grid-cols-2 gap-x-6">
            <div>
              <Form.Item
                hasFeedback
                label="Company Group"
                // requiredMark="optional"
                // name="groupId"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Group"
                  optionFilterProp="children"
                  onChange={(val) => setGroupId(val)}
                  onSearch={(e) => handleSearch(e, "group")}
                  filterOption={() => {
                    return true;
                  }}
                  options={groupOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Employee Name"
                rules={[
                  {
                    required: false,
                    message: "Name is Required!",
                  },
                ]}
                name="name"
                // initialValue={employeeData?.data?.name}
              >
                <Input
                  id="name"
                  type="text"
                  placeholder="Enter Employee Name"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Employee ID"
                rules={[
                  {
                    required: false,
                    message: "Employee Id is Required!",
                  },
                ]}
                name="employeeId"
              >
                <Input
                  id="employeeId"
                  type="text"
                  placeholder="Enter Employee ID"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label={`Department`}
                rules={[
                  {
                    required: false,
                    message: "DepartmentId is required!",
                  },
                ]}
                name="department"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Department"
                  optionFilterProp="children"
                  onChange={(val) => setDepartmentId(val)}
                  onSearch={(val) => {
                    handleSearch?.(val, "department");
                  }}
                  filterOption={() => {
                    return true;
                  }}
                  options={departmentOptions?.list}
                ></Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Designation"
                // requiredMark="optional"
                name="designation"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Designations"
                  optionFilterProp="children"
                  onChange={(val) => setDesignationId(val)}
                  onSearch={(e) => handleSearch(e, "designation")}
                  filterOption={() => {
                    return true;
                  }}
                  options={designation?.list}
                ></Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="Email Address"
                rules={[
                  {
                    required: false,
                    message: "Email is required!",
                  },
                  // { type: 'string', max: 11, min: 11 },
                  {
                    validator: async (_, email) => {
                      if (
                        email &&
                        !String(email)
                          .toLowerCase()
                          ?.match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          )
                      ) {
                        return Promise.reject(new Error("Enter valid email"));
                      }
                    },
                  },
                ]}
                name="emailAddress"
              >
                <Input
                  id="emailAddress"
                  type="email"
                  placeholder="Enter Email"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Mobile Number"
                rules={[
                  {
                    required: !employeeData?.data?.mobileNumber,
                    message: "Mobile Number is required!",
                  },
                  {
                    validator: async (_, names) => {
                      if (
                        !employeeData?.data?.mobileNumber &&
                        !names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)
                      ) {
                        return Promise.reject(
                          new Error("Enter valid mobile number")
                        );
                      }
                    },
                  },
                ]}
                name="mobileNumber"
                initialValue={employeeData?.data?.mobileNumber || "01"}
              >
                <Input
                  disabled={employeeData?.data}
                  id="mobileNumber"
                  type="contact"
                  addonBefore={"+88"}
                  placeholder="Enter Mobile Number"
                  defaultValue={employeeData?.data?.mobileNumber}
                  value={employeeData?.data?.mobileNumber}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Mobile Operator"
                name="mobileOperator"
                rules={[
                  {
                    required: false,
                    message: "Mobile Operator is Required!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Mobile Operator Type"
                  onChange={(val) => setMobileOperator(val)}
                >
                  {Object.values(mobileOperatorType)?.map((type, i) => (
                    <Option value={type} key={i}>
                      {type?.split("_").join(" ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Operator Type"
                name="simType"
                rules={[
                  {
                    required: false,
                    message: "Operator Type is Required!",
                  },
                ]}
              >
                <Select
                  placeholder="Select SIM Operator Type"
                  onChange={(val) => setOperatorType(val)}
                >
                  {Object.values(simOperatorType)?.map((type: any, i: any) => (
                    <Option value={type} key={i}>
                      {type?.split("_").join(" ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Monthly TopUp Amount"
                name="monthlyTopUpAmount"
                initialValue={"0"}
              >
                <Input
                  id="monthlyTopUpAmount"
                  type="number"
                  placeholder="Enter monthly TopUpA mount"
                  min={0}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item hasFeedback label="Credit Limit" name="creditLimit">
                <Input
                  id="creditLimit"
                  type="number"
                  placeholder="Enter Credi tLimit"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="Discount Percentage"
                name="discountPercentage"
              >
                <Input
                  id="discountPercentage"
                  type="number"
                  placeholder="Enter Discount Percentage"
                  min={0}
                  max={50}
                />
              </Form.Item>
            </div>
          </div>
          <SubmitResetBtn />
        </div>
      </Form>
    </div>
  );
};

export default AddEmployee;
