import React from "react";
import MainLayout from "../../components/Layouts";
import TopUpDetails from "../../components/TopUp-History/Details";

const TopUpDetailsPage = () => {
  return (
    <MainLayout>
      <TopUpDetails />
    </MainLayout>
  );
};

export default TopUpDetailsPage;
