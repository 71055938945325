import BreadCrumb from "../Layouts/Breadcrumb";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

const Dashboard = (): ReactElement => {
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const config = {
    // data2,
    xField: "month",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };

  const config3 = {
    //data,
    xField: "month",
    yField: "value",
    stepType: "vh",
  };

  return (
    <>
      <BreadCrumb title="Dashboard" />

      <div className="">
        <div className="grid grid-cols-2 gap-6 mb-6 w-full xl:grid-cols-4 2xl:grid-cols-4">
          <Link to="#">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    className="w-8 h-8"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900 sm:text-2xl">
                    {initialState?.corporateInfo?.balance || 0}
                  </span>
                  <h3 className="text-base font-normal text-gray-500">
                    Balance
                  </h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
