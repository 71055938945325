import MainLayout from "../../components/Layouts";
import CompanyDesignationList from "../../components/Designation/index";
import React from "react";

const DesignationPage = () => {
  return (
    <MainLayout>
      <CompanyDesignationList />
    </MainLayout>
  );
};

export default DesignationPage;
