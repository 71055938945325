import MainLayout from "../../components/Layouts";
import CompanyTopUp from "../../components/TopUp/index";
import React from "react";

const TopUpPage = () => {
  return (
    <MainLayout>
      <CompanyTopUp />
    </MainLayout>
  );
};

export default TopUpPage;
