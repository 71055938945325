import MainLayout from "../../components/Layouts";
import CompanyEmployeeTopUp from "../../components/Employee/EmployeeTopUp";
import React from "react";

const EmployeeTopUpPage = () => {
  return (
    <MainLayout>
      <CompanyEmployeeTopUp />
    </MainLayout>
  );
};

export default EmployeeTopUpPage;
