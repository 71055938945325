import MainLayout from "../../components/Layouts";
import CompanyEmployeeList from "../../components/Employee/index";
import React from "react";

const EmployeeListPage = () => {
  return (
    <MainLayout>
      <CompanyEmployeeList />
    </MainLayout>
  );
};

export default EmployeeListPage;
