import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Button,
  Empty,
  Form,
  Input,
  Select,
  Spin,
  Switch,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";
import { Link } from "react-router-dom";

const EmployeeList = (): ReactElement => {
  const fetchRef = useRef(0);
  const navigate = useNavigate();
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(20);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [groupId, setGroupId] = useState<any>();
  const [groupOptions, setGroupOptions] = useState({
    loading: false,
    list: [],
  });
  const [employeeData, setEmployeeData] = useState<any>({
    loading: false,
    data: null,
  });
  const [employeeOptions, setEmployeeOptions] = useState({
    loading: false,
    list: [],
  });

  const getGroupOptions = useCallback(
    async (key?: string) => {
      setGroupOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      const res = await axios.get(
        `${encodedUri}/company/admin/all?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGroupOptions({
        loading: false,
        list: res?.data?.groups?.map((group: any) => ({
          label: group.groupName,
          value: group.id,
        })),
      });
    },
    [companyId]
  );
  const getEmployeeOptions = useCallback(
    async (mobileNumber: any) => {
      setEmployeeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(`${encodedUri}/employee/all?companyId=${companyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setEmployeeOptions({
            loading: false,
            list: res.data?.employees?.map(
              (employee: { name: any; mobileNumber: string }) => ({
                label: employee.name,
                value: employee.mobileNumber.replace("+88", ""),
              })
            ),
          });
        })
        .catch((err) => {
          setEmployeeOptions({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [companyId]
  );
  const getEmployee = useCallback(async () => {
    setEmployeeData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/employee/all?companyId=${companyId}` +
          (groupId ? `&groupId=${groupId}` : ``) +
          (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``) +
          (page
            ? `&page=${
                page == employeeData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setEmployeeData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setEmployeeData({ loading: false, data: [] });
        console.error("Employees: Error", err);
      });
  }, [companyId, groupId, mobileNumber, page, limit]);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          employeeId: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getEmployee();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const reseAllFieldData = () => {
    setGroupId("");
    setMobileNumber("");
    form.resetFields();
  };

  useEffect(() => {
    getEmployee();
  }, [getEmployee]);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "group") getGroupOptions(value);
        if (field === "employee") getEmployeeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getGroupOptions, getEmployeeOptions]);
  useEffect(() => {
    if (showSearch) {
      getGroupOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title="Employee List"
        subTitle={`${
          employeeData?.data?.totalElements ||
          employeeData?.data?.employees?.length ||
          0
        } Employee(s)`}
        extra={[
          <AddBtn
            onClick={() => {
              navigate("/employee-create");
            }}
            key={2}
          />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="phone_search" initialValue={mobileNumber}>
              <AutoComplete
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "employee")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setMobileNumber(val?.toString())
                }
                options={employeeOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  employeeOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by User Phone"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  loading={employeeOptions.loading}
                  maxLength={11}
                  type="number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="group_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Group"
                optionFilterProp="children"
                onChange={(val) => setGroupId(val)}
                onSearch={(e) => handleSearch(e, "group")}
                filterOption={() => {
                  return true;
                }}
                options={groupOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {employeeData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Email</th>
                      <th scope="col">TopUp Amount</th>
                      <th scope="col">Credit Limit</th>
                      <th scope="col">Discount Percentage</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {employeeData?.data?.employees?.length ? (
                      employeeData?.data?.employees?.map(
                        (employee: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedEmployee(employee)}
                          >
                            <td>
                              <div className="checkbox-large flex items-center gap-2">
                                <span className="font-medium text-gray-500 ml-2">
                                  {employee?.name}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {employee?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {employee?.emailAddress}
                              </span>
                            </td>

                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {employee?.monthlyTopUpAmount}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {employee?.creditLimit}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {employee?.discountPercentage}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={employee?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(employee?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  employee?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Link
                                  title="Edit Employee"
                                  to={`/employee/${employee?.id}/edit`}
                                  // target="_blank"
                                  className="py-2 px-4 rounded-full m-0 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                >
                                  <EditOutlined />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <Pagination
          {...employeeData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default EmployeeList;
