import MainLayout from "../../components/Layouts";
import CompanyEmployeeAdd from "../../components/Employee/AddEmployee";
import React from "react";

const EmployeeAddPage = () => {
  return (
    <MainLayout>
      <CompanyEmployeeAdd />
    </MainLayout>
  );
};

export default EmployeeAddPage;
