import { EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, DatePicker, Empty, Form } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import moment from "moment";
const { RangePicker } = DatePicker;

const TopUpList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const pageType: any = getParamValue(loc.search, "page-type");
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(20);
  const [showSearch, setShowSearch] = useState(true);
  const [range, setRange] = useState<any>({
    from: "2021-02-28T18:00:00.000Z",
    to: "2023-04-05T17:59:59.000",
  });
  const [topUpHistoryData, setTopUpHistoryData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTopUpHistory = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setTopUpHistoryData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/topup/company-history` +
          (from ? `?from=${from}` : ``) +
          (to ? `&to=${to}` : ``) +
          (page
            ? `&page=${
                page == topUpHistoryData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTopUpHistoryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTopUpHistoryData({ loading: false, data: [] });
        console.error("Banners: Error", err);
      });
  }, [range, limit, page]);

  //
  const reseAllFieldData = () => {
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };
  useEffect(() => {
    getTopUpHistory();
  }, [getTopUpHistory]);

  return (
    <>
      <BreadCrumb
        title="TopUp List"
        subTitle={`${
          topUpHistoryData?.data?.totalElements ||
          topUpHistoryData?.data?.companyTopUps?.length ||
          0
        } Topup(s)`}
        extra={<></>}
      />
      {showSearch && !pageType && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                // defaultValue={
                //   range.from != null && range.to != null
                //     ? [moment(range.from), moment(range.to)]
                //     : null
                // }
                onChange={(_e, f) => {
                  // console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {topUpHistoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Number Of Request</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {topUpHistoryData?.data?.companyTopUps?.length ? (
                      topUpHistoryData?.data?.companyTopUps?.map(
                        (topup: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td
                              onClick={() =>
                                navigate(`/topup-list/${topup?.id}/details`)
                              }
                            >
                              <span className="font-medium text-gray-500 ml-2">
                                {moment(topup?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.numberOfRequest}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {topup?.totalAmount}
                              </span>
                            </td>
                            <td>
                              <Button
                                title="View History"
                                onClick={() => {
                                  navigate(`/topup-list/${topup?.id}/details`);
                                }}
                                className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                              >
                                <EyeOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...topUpHistoryData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default TopUpList;
function UnLoadWindow() {
  throw new Error("Function not implemented.");
}
