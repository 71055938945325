import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { DatePicker, Empty, Form } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";
import { AddBtn, Loader } from "../common";

const TopUpList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [showSearch, setShowSearch] = useState(true);

  const [blanceHistoryData, setBlanceHistoryData] = useState<any>({
    loading: false,
    data: null,
  });

  const getBlanceHistory = useCallback(async () => {
    setBlanceHistoryData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(`${encodedUri}/company/balance-history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBlanceHistoryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setBlanceHistoryData({ loading: false, data: [] });
        console.error("Banners: Error", err);
      });
  }, []);

  const reseAllFieldData = () => {
    form.resetFields();
  };
  useEffect(() => {
    getBlanceHistory();
  }, [getBlanceHistory]);

  return (
    <>
      <BreadCrumb
        title="Company Balance History List"
        subTitle={`${
          blanceHistoryData?.data?.totalElements ||
          blanceHistoryData?.data?.companyBalanceHistoryList?.length ||
          0
        } Blance History(s)`}
        extra={[
          <AddBtn
            onClick={() => {
              navigate("/cash-payment");
            }}
          />,
        ]}
      />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {blanceHistoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Account Name</th>
                      <th scope="col">Deposited Amount</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Bank Name</th>
                      <th scope="col">Bank AccountNo</th>
                      <th scope="col">Transaction Reference</th>
                      <th scope="col">Credit Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {blanceHistoryData?.data?.companyBalanceHistoryList
                      ?.length ? (
                      blanceHistoryData?.data?.companyBalanceHistoryList?.map(
                        (comapnyBlance: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {comapnyBlance?.accountName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {comapnyBlance?.depositedAmount}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {comapnyBlance?.paymentMethod}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {comapnyBlance?.bankName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {comapnyBlance?.bankAccountNo}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {comapnyBlance?.transactionReference}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {comapnyBlance?.creditStatus}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopUpList;
function UnLoadWindow() {
  throw new Error("Function not implemented.");
}
