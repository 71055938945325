import MainLayout from "../../components/Layouts";
import CompanyDepartmentList from "../../components/Department/index";
import React from "react";

const DepartmentPage = () => {
  return (
    <MainLayout>
      <CompanyDepartmentList />
    </MainLayout>
  );
};

export default DepartmentPage;
