import { Layout, Menu } from "antd";
import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { SIDEBAR_COLLAPSE } from "../../../redux/auth/authType";
import LogoImg from "../../../images/logo.svg";
import IconImg from "../../../images/favicon.png";
import React from "react";
import {
  faAddressBook,
  faBuildingUser,
  faCar,
  faCode,
  faComment,
  faHouse,
  faLaptop,
  faObjectGroup,
  faUserGroup,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
const { Sider } = Layout;
const { SubMenu } = Menu;

const MainSidebar = (): ReactElement => {
  const { sidebarCollapse, type } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const setCollapsed = (value: boolean) => {
    dispatch({
      type: SIDEBAR_COLLAPSE,
      payload: {
        sidebarCollapse: value,
      },
    });
  };

  return (
    <>
      <Sider
        width={230}
        collapsible
        collapsed={sidebarCollapse}
        onCollapse={(value) => {
          setCollapsed(value);
        }}
      >
        <div className="logo sticky top-0 z-10">
          <Link to="/">
            <img
              src={sidebarCollapse ? IconImg : LogoImg}
              alt="jachai parcel"
              className="logoImg"
            />
          </Link>
        </div>
        <Menu
          // defaultOpenKeys={[getServicePaths(pathname)]}
          mode="inline"
          // inlineCollapsed={this?.state?.collapsed}
          // onOpenChange={this.onOpenChange}
          theme="dark"
        >
          <Menu.Item
            icon={<FontAwesomeIcon icon={faHouse} />}
            className={pathname === "/" ? "active" : ""}
          >
            <Link to={"/"}>Dashboard</Link>
          </Menu.Item>
          {/* <SubMenu icon={<FontAwesomeIcon icon={faUsers} />} title="Employee">
          </SubMenu> */}
          <Menu.Item
            icon={<FontAwesomeIcon icon={faObjectGroup} />}
            className={pathname === "/group-list" ? "active" : ""}
          >
            <Link to="/group-list">Company Group</Link>
          </Menu.Item>
          <Menu.Item
            icon={<FontAwesomeIcon icon={faUserGroup} />}
            className={pathname === "/employee-group-list" ? "active" : ""}
          >
            <Link to="/employee-group-list">Employee Group</Link>
          </Menu.Item>
          <Menu.Item
            icon={<FontAwesomeIcon icon={faBuildingUser} />}
            className={pathname === "/department-list" ? "active" : ""}
          >
            <Link to="/department-list">Department</Link>
          </Menu.Item>
          <Menu.Item
            icon={<FontAwesomeIcon icon={faAddressBook} />}
            className={pathname === "/designation-list" ? "active" : ""}
          >
            <Link to="/designation-list">Designation</Link>
          </Menu.Item>
          <Menu.Item
            icon={<FontAwesomeIcon icon={faUserPlus} />}
            className={pathname === "/employee-list" ? "active" : ""}
          >
            <Link to="/employee-list">Employee List</Link>
          </Menu.Item>
          <SubMenu icon={<FontAwesomeIcon icon={faUsers} />} title="TopUp">
            <Menu.Item
              icon={<FontAwesomeIcon icon={faUserPlus} />}
              className={pathname === "/employee-topup" ? "active" : ""}
            >
              <Link to="/employee-topup">Employee TopUp</Link>
            </Menu.Item>
            <Menu.Item
              icon={<FontAwesomeIcon icon={faComment} />}
              className={pathname === "/topup" ? "active" : ""}
            >
              <Link to={"/topup"}>Bulk TopUp</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            icon={<FontAwesomeIcon icon={faLaptop} />}
            className={pathname === "/topup-list" ? "active" : ""}
          >
            <Link to={"/topup-list"}>TopUp List</Link>
          </Menu.Item>
          <Menu.Item
            icon={<FontAwesomeIcon icon={faCar} />}
            className={pathname === "/payment-request-list" ? "active" : ""}
          >
            <Link to={"/payment-request-list"}>Payment Request</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};

export default MainSidebar;
