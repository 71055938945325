import { ReactElement, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import React from "react";
import _ from "lodash";
import { responseNotification } from "../../utils/notify";
import { LoaderFull } from "../common";

const EmployeeTopUp = ({
  employees,
  selectedEmployeeId,
}: {
  employees: any;
  selectedEmployeeId?: [];
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data?: any) => {
    setLoading(true);

    const readyData = data && {
      topUpRequests: selectedEmployeeId?.map((id) => employees[id]),
    };

    await fetch(`${process.env.REACT_APP_TOPUP_API}/topup/company`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("TopUp sending Successfully", "success");
          // if (onCloseMethod) {
          //   onCloseMethod();
          // }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  if (loading) return <LoaderFull />;

  return loading ? (
    <LoaderFull />
  ) : (
    <>
      <p className="mb-4">
        If you agree to send a top-up to all employees, then click the top-up
        button.
      </p>

      <Button size="large" type="primary" danger onClick={onSubmit}>
        TopUp
      </Button>
      {/* {employees?.[0]?.map((employee: any) =>
        console.log("employee:", employee)
      )} */}
    </>
  );
};

export default EmployeeTopUp;
