import MainLayout from "../../components/Layouts";
import CompanyTopUpList from "../../components/TopUp-History/index";
import React from "react";

const TopUpListPage = () => {
  return (
    <MainLayout>
      <CompanyTopUpList />
    </MainLayout>
  );
};

export default TopUpListPage;
