import { Button, Form, Input, Select } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";

const AddEmployeeGroup = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const fetchRef = useRef<any>(0);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState();
  const [employeeGroupData, setEmployeeGroupData] = useState<any>(undefined);
  const [groupOptions, setGroupOptions] = useState({
    loading: false,
    list: [],
  });
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      companyGroupId: groupId,
      groupName: data.groupName,
      companyId: companyId,
    };

    if (employeeGroupData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee/group`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          groupId: employeeGroupData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee/group/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Group Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getGroupOptions = useCallback(
    async (key?: string) => {
      setGroupOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/company/admin/all?companyId=${companyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setGroupOptions({
            loading: false,
            list: res.data?.groups?.map((group: any) => ({
              label: group?.groupName,
              value: group?.id,
            })),
          });
        })
        .catch((err) => {
          setGroupOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [companyId]
  );
  const handleSearch = useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef!.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "group") getGroupOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getGroupOptions]);

  useEffect(() => {
    getGroupOptions();
  }, [getGroupOptions]);
  useEffect(() => {
    if (visibleData) {
      setEmployeeGroupData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (employeeGroupData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [employeeGroupData, form, visibleData]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...employeeGroupData,
        }}
      >
        <Form.Item
          hasFeedback
          label={`Company Group`}
          rules={[
            {
              required: true,
              message: "Group is required!",
            },
          ]}
          name="companyGroupId"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Company Group"
            optionFilterProp="children"
            onChange={(val) => setGroupId(val)}
            onSearch={(val) => {
              handleSearch?.(val, "group");
            }}
            filterOption={() => {
              return true;
            }}
            options={groupOptions?.list}
          ></Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Employee Group Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="groupName"
        >
          <Input id="groupName" type="text" placeholder="Enter Group Name" />
        </Form.Item>
        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddEmployeeGroup;
