import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Drawer, Empty, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleDepartment from "./Details";
import AddDepartment from "./AddDepartment";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";

const DepartmentList = (): ReactElement => {
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedDepartment, setSelectedDepartment] = useState(undefined);
  const [selectedDepartmentForEdit, setSelectedDepartmentForEdit] =
    useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [departmentData, setDepartmentData] = useState<any>({
    loading: false,
    data: null,
  });

  const getDepartment = useCallback(async () => {
    setDepartmentData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(`${encodedUri}/company/department/all?companyId=${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDepartmentData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setDepartmentData({ loading: false, data: [] });
        console.error("Departments: Error", err);
      });
  }, [companyId]);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/company/department/update`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            departmentId: id,
            isActive: val,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getDepartment();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getDepartment();
  }, [getDepartment]);

  const onClose = () => {
    getDepartment();
    setVisible(undefined);
    setSelectedDepartment(undefined);
    setSelectedDepartmentForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Department List"
        subTitle={`${
          departmentData?.data?.totalElements ||
          departmentData?.data?.departments?.length ||
          0
        } Department(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {departmentData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Department</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {departmentData?.data?.departments?.length ? (
                      departmentData?.data?.departments?.map(
                        (companyDep: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedDepartment(companyDep)}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {companyDep?.name}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={companyDep?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(companyDep?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  companyDep?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    companyDep?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(companyDep?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(companyDep?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {companyDep?.updatedBy?.name ||
                                  companyDep?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedDepartmentForEdit(companyDep)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...departmentData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Department"
            : selectedDepartmentForEdit
            ? "Edit Department"
            : "Department Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedDepartment || selectedDepartmentForEdit}
        width={450}
      >
        {visible || selectedDepartmentForEdit ? (
          <AddDepartment
            onCloseMethod={onClose}
            visibleData={selectedDepartmentForEdit}
          />
        ) : (
          <SingleDepartment DepartmentDetails={selectedDepartment} />
        )}
      </Drawer>
    </>
  );
};

export default DepartmentList;
