import React from "react";
import { ReactElement } from "react";

const SingleDepartment = ({
  DepartmentDetails,
}: {
  DepartmentDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Department Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{DepartmentDetails?.name}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleDepartment;
