import MainLayout from "../../components/Layouts";
import EmployeeGroupList from "../../components/EmployeeGroup/index";
import React from "react";

const EmployeeGroupListPage = () => {
  return (
    <MainLayout>
      <EmployeeGroupList />
    </MainLayout>
  );
};

export default EmployeeGroupListPage;
