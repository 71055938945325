import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Drawer, Empty, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleGroup from "./Details";
import AddGroup from "./AddGroup";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";

const GroupList = (): ReactElement => {
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedGroupForEdit, setSelectedGroupForEdit] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [groupData, setGroupData] = useState<any>({
    loading: false,
    data: null,
  });

  const getGroup = useCallback(async () => {
    setGroupData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(`${encodedUri}/company/admin/all?companyId=${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setGroupData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setGroupData({ loading: false, data: [] });
        console.error("Groups: Error", err);
      });
  }, [companyId]);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/company/admin`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          groupId: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getGroup();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getGroup();
  }, [getGroup]);

  const onClose = () => {
    getGroup();
    setVisible(undefined);
    setSelectedGroup(undefined);
    setSelectedGroupForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Group List"
        subTitle={`${
          groupData?.data?.totalElements || groupData?.data?.groups?.length || 0
        } Group(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {groupData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Group</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {groupData?.data?.groups?.length ? (
                      groupData?.data?.groups?.map((group: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() => setSelectedGroup(group)}
                        >
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {group?.groupName}
                            </span>
                          </td>
                          <td>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              defaultChecked={group?.isActive}
                              onChange={(val, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onStatusChange(group?.id, val);
                              }}
                              loading={
                                confirmLoading && group?.id === confirmLoading
                              }
                            />
                          </td>
                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(group?.updatedAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(group?.updatedAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(group?.updatedAt).format("lll")}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {group?.updatedBy?.name ||
                                group?.updatedBy?.mobileNumber}
                            </span>
                          </td>
                          <td>
                            <div className="flex gap-2">
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() => setSelectedGroupForEdit(group)}
                              >
                                <EditOutlined />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...groupData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Group"
            : selectedGroupForEdit
            ? "Edit Group"
            : "Group Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedGroup || selectedGroupForEdit}
        width={450}
      >
        {visible || selectedGroupForEdit ? (
          <AddGroup
            onCloseMethod={onClose}
            visibleData={selectedGroupForEdit}
          />
        ) : (
          <SingleGroup GroupDetails={selectedGroup} />
        )}
      </Drawer>
    </>
  );
};

export default GroupList;
