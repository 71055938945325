import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Button, Drawer, Empty, Form, Select, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleDesignation from "./Details";
import AddDesignation from "./AddDesignation";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";

const DesignationList = (): ReactElement => {
  const fetchRef = useRef(0);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedDesignation, setSelectedDesignation] = useState(undefined);
  const [selectedDesignationForEdit, setSelectedDesignationForEdit] =
    useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [departmentId, setDepartmentId] = useState<any>();
  const [designationData, setDesignationData] = useState<any>({
    loading: false,
    data: null,
  });

  const [departmentOptions, setDepartmentOptions] = useState({
    loading: false,
    list: [],
  });

  const getDepartmentOptions = useCallback(async (key?: string) => {
    setDepartmentOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    const res = await axios.get(
      `${encodedUri}/company/department/all?companyId=${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setDepartmentOptions({
      loading: false,
      list: res?.data?.departments?.map((department: any) => ({
        label: department.name,
        value: department.id,
      })),
    });
  }, []);
  const getDesignation = useCallback(async () => {
    setDesignationData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/company/department/designation/all?companyId=${companyId}` +
          (departmentId ? `&departmentId=${departmentId}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDesignationData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setDesignationData({ loading: false, data: [] });
        console.error("Designations: Error", err);
      });
  }, [companyId, departmentId]);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/company/department/designation`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            designationId: id,
            isActive: val,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getDesignation();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getDesignation();
  }, [getDesignation]);

  const reseAllFieldData = () => {
    setDepartmentId("");
    form.resetFields();
  };

  useEffect(() => {
    if (showSearch) {
      getDepartmentOptions("");
    }
  }, [showSearch]);
  const onClose = () => {
    getDesignation();
    setVisible(undefined);
    setSelectedDesignation(undefined);
    setSelectedDesignationForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "brand") getDepartmentOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getDepartmentOptions]);
  return (
    <>
      <BreadCrumb
        title="Designation List"
        subTitle={`${
          designationData?.data?.totalElements ||
          designationData?.data?.designations?.length ||
          0
        } Designation(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Department"
                optionFilterProp="children"
                onChange={(val) => setDepartmentId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={departmentOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {designationData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Designation</th>
                      <th scope="col">Department</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {designationData?.data?.designations?.length ? (
                      designationData?.data?.designations?.map(
                        (companyDep: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedDesignation(companyDep)}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {companyDep?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {companyDep?.department?.name}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={companyDep?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(companyDep?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  companyDep?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    companyDep?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(companyDep?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(companyDep?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {companyDep?.updatedBy?.name ||
                                  companyDep?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedDesignationForEdit(companyDep)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...designationData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Designation"
            : selectedDesignationForEdit
            ? "Edit Designation"
            : "Designation Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedDesignation || selectedDesignationForEdit}
        width={450}
      >
        {visible || selectedDesignationForEdit ? (
          <AddDesignation
            onCloseMethod={onClose}
            visibleData={selectedDesignationForEdit}
          />
        ) : (
          <SingleDesignation DesignationDetails={selectedDesignation} />
        )}
      </Drawer>
    </>
  );
};

export default DesignationList;
