import MainLayout from "../../components/Layouts";
import CashPaymentRequest from "../../components/CashPayment/paymentRequest";
import React from "react";

const CashPaymentPage = () => {
  return (
    <MainLayout>
      <CashPaymentRequest />
    </MainLayout>
  );
};

export default CashPaymentPage;
