import MainLayout from "../../components/Layouts";
import CompanyGroupList from "../../components/Group/index";
import React from "react";

const GroupListPage = () => {
  return (
    <MainLayout>
      <CompanyGroupList />
    </MainLayout>
  );
};

export default GroupListPage;
