import { Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";

import BreadCrumb from "../Layouts/Breadcrumb";

const CashPaymentRequest = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);

  const [loading, setLoading] = useState(false);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [paymentMethod, setPaymentMethod] = useState("NONE");
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [form] = Form.useForm();

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      accountName: data.accountName,
      amount: data.amount,
      bankName: data.bankName || "N/A",
      bankAccountNo: data.bankAccountNo || "N/A",
      companyId: initialState?.companyId,
      note: data.note,
      paymentMethod: paymentMethod,
      pictureOfDocument: generatedImgUrl || data.pictureOfDocument,
      transactionReference: data.transactionReference || "N/A",
    };

    await fetch(
      `${process.env.REACT_APP_RIDER_API}/company/cash-collection-request-from-company`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Payment Request Sent Successfully", "success");
          form.resetFields();
          setGeneratedImgUrl(undefined);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    form?.resetFields();
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Payment Request" />
      <div>
        <Row
          gutter={25}
          align="middle"
          justify="center"
          className="product-table"
        >
          <Col span={12}>
            <Card>
              <Form layout="vertical" onFinish={onSubmit} form={form}>
                <Form.Item
                  hasFeedback
                  label="Account Name"
                  name="accountName"
                  rules={[
                    {
                      required: true,
                      message: "Account name is required",
                    },
                  ]}
                >
                  <Input
                    id="accountName"
                    type="text"
                    placeholder="Account Name"
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Amount is required",
                    },
                  ]}
                >
                  <Input
                    id="amount"
                    type="number"
                    min={0}
                    placeholder="Amount"
                  />
                </Form.Item>

                <Form.Item
                  hasFeedback
                  label="Payment Method"
                  rules={[
                    {
                      required: true,
                      message: "Payment method is Required!",
                    },
                  ]}
                  name="paymentMethod"
                  initialValue={paymentMethod}
                >
                  <Select
                    options={[
                      { value: "NONE", label: "NONE" },
                      { value: "CASH", label: "CASH" },
                      { value: "BKASH", label: "BKASH" },
                      { value: "NAGAD", label: "NAGAD" },
                      { value: "ROCKET", label: "ROCKET" },
                      { value: "BANK", label: "BANK" },
                    ]}
                    placeholder="Enter Notification Type"
                    onChange={(val) => setPaymentMethod(val)}
                  />
                </Form.Item>
                {paymentMethod === "BANK" && (
                  <>
                    <Form.Item
                      hasFeedback
                      label="Bank Name"
                      name="bankName"
                      rules={[
                        {
                          required: false,
                          message: "Bank name is required",
                        },
                      ]}
                    >
                      <Input
                        id="bankName"
                        type="text"
                        placeholder="Bank Name"
                      />
                    </Form.Item>
                    <Form.Item
                      hasFeedback
                      label="Bank Account No"
                      name="bankAccountNo"
                      rules={[
                        {
                          required: false,
                          message: "Bank account no is required",
                        },
                      ]}
                    >
                      <Input
                        id="bankAccountNo"
                        type="text"
                        placeholder="Bank Account No"
                      />
                    </Form.Item>
                  </>
                )}

                {paymentMethod !== "BANK" && paymentMethod !== "NONE" && (
                  <>
                    <Form.Item
                      hasFeedback
                      label="Transaction Reference"
                      name="transactionReference"
                      rules={[
                        {
                          required: false,
                          message: "Transaction reference is required",
                        },
                      ]}
                    >
                      <Input
                        id="transactionReference"
                        type="text"
                        placeholder="Transaction Reference"
                      />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  hasFeedback
                  label="Note"
                  name="note"
                  rules={[
                    {
                      required: true,
                      message: "Note is required",
                    },
                  ]}
                >
                  <Input.TextArea rows={5} id="note" placeholder="Note" />
                </Form.Item>

                <h4 className="mt-6 mb-2 font-medium text-lg text-gray-600">
                  Picture Of Document:
                </h4>
                <div className="">
                  <ImageUploader
                    imgUrl={generatedImgUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>

                <div className="mt-10">
                  <SubmitResetBtn
                    onClickSubmit={(e) => {
                      e.preventDefault();
                      setStartUpload("Uploading");
                    }}
                    onClickReset={() => {
                      form?.resetFields();
                      setGeneratedImgUrl(undefined);
                      setStartUpload(undefined);
                    }}
                    disabled={loading}
                    loading={loading}
                  />
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CashPaymentRequest;
