import { Button, Form, Input, Select } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
import { debounce } from "lodash";
import axios from "axios";

const AddDesignation = ({ visibleData, onCloseMethod }: any) => {
  const fetchRef = useRef<any>(0);
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);

  const companyId = initialState?.companyId;
  const [departmentId, setDepartmentId] = useState();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [designationData, setDesignationData] = useState<any>(undefined);
  const [departmentOptions, setDepartmentOptions] = useState({
    loading: false,
    list: [],
  });

  const getDepartmentOptions = useCallback(
    async (key?: string) => {
      setDepartmentOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/company/department/all?companyId=${companyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDepartmentOptions({
            loading: false,
            list: res.data?.departments?.map((department: any) => ({
              label: department?.name,
              value: department?.id,
            })),
          });
        })
        .catch((err) => {
          setDepartmentOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [companyId]
  );

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      departmentId: departmentId,
      name: data.name,
    };

    if (designationData) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/company/department/designation`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            departmentId: data?.departmentId,
            designationName: data?.name,
            designationId: designationData?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Designation Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/company/department/designation/add`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Designation Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef!.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "department") getDepartmentOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getDepartmentOptions]);

  useEffect(() => {
    getDepartmentOptions();
  }, [getDepartmentOptions]);

  useEffect(() => {
    if (visibleData) {
      setDesignationData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (designationData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [designationData, form, visibleData]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...designationData,
        }}
      >
        <Form.Item
          hasFeedback
          label={`Department`}
          rules={[
            {
              required: true,
              message: "DepartmentId is required!",
            },
          ]}
          name="departmentId"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Department"
            optionFilterProp="children"
            onChange={(val) => setDepartmentId(val)}
            onSearch={(val) => {
              handleSearch?.(val, "department");
            }}
            filterOption={() => {
              return true;
            }}
            options={departmentOptions?.list}
          ></Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Designation Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Designation Name" />
        </Form.Item>
        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddDesignation;
