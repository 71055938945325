import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Button, Drawer, Empty, Form, Select, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleGroup from "./Details";
import AddEmployeeGroup from "./AddEmployeeGroup";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";

const employeeGroupList = (): ReactElement => {
  const fetchRef = useRef(0);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const companyId = initialState?.companyId;
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [companyGroupId, setCompanyGroupId] = useState<any>();
  const [selectedEmployeeGroup, setSelectedEmployeeGroup] = useState(undefined);
  const [selectedEmployeeGroupForEdit, setSelectedEmployeeGroupForEdit] =
    useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [employeeGroupData, setEmployeeGroupData] = useState<any>({
    loading: false,
    data: null,
  });
  const [groupOptions, setGroupOptions] = useState({
    loading: false,
    list: [],
  });

  const getGroupOptions = useCallback(async (key?: string) => {
    setGroupOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    const res = await axios.get(
      `${encodedUri}/company/admin/all?companyId=${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setGroupOptions({
      loading: false,
      list: res?.data?.groups?.map((group: any) => ({
        label: group.groupName,
        value: group.id,
      })),
    });
  }, []);
  useEffect(() => { 
    if (showSearch) {
      getGroupOptions("");
    }
  }, [showSearch]);
  const getEmployeeGroup = useCallback(async () => {
    setEmployeeGroupData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/employee/group/all?companyId=${companyId}` +
          (companyGroupId ? `&companyGroupId=${companyGroupId}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setEmployeeGroupData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setEmployeeGroupData({ loading: false, data: [] });
        console.error("Groups: Error", err);
      });
  }, [companyId, companyGroupId]);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/employee/group`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          groupId: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getEmployeeGroup();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getEmployeeGroup();
  }, [getEmployeeGroup]);

  const reseAllFieldData = () => {
    setCompanyGroupId("");
    form.resetFields();
  };
  const onClose = () => {
    getEmployeeGroup();
    setVisible(undefined);
    setSelectedEmployeeGroup(undefined);
    setSelectedEmployeeGroupForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "group") getGroupOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getGroupOptions]);

  return (
    <>
      <BreadCrumb
        title="Employee Group List"
        subTitle={`${
          employeeGroupData?.data?.totalElements ||
          employeeGroupData?.data?.groups?.length ||
          0
        } Group(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Department"
                optionFilterProp="children"
                onChange={(val) => setCompanyGroupId(val)}
                onSearch={(e) => handleSearch(e, "group")}
                filterOption={() => {
                  return true;
                }}
                options={groupOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {employeeGroupData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Employee Group</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {employeeGroupData?.data?.groups?.length ? (
                      employeeGroupData?.data?.groups?.map(
                        (group: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedEmployeeGroup(group)}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {group?.groupName}
                              </span>
                            </td>

                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={group?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(group?.id, val);
                                }}
                                loading={
                                  confirmLoading && group?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(group?.updatedAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(group?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(group?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {group?.updatedBy?.name ||
                                  group?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedEmployeeGroupForEdit(group)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...employeeGroupData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Employee Group"
            : selectedEmployeeGroupForEdit
            ? "Edit Employee Group"
            : "Group Employee Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedEmployeeGroup || selectedEmployeeGroupForEdit}
        width={450}
      >
        {visible || selectedEmployeeGroupForEdit ? (
          <AddEmployeeGroup
            onCloseMethod={onClose}
            visibleData={selectedEmployeeGroupForEdit}
          />
        ) : (
          <SingleGroup GroupDetails={selectedEmployeeGroup} />
        )}
      </Drawer>
    </>
  );
};

export default employeeGroupList;
